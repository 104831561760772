import { TranslationsKeys } from '@shared/type/i18n.type';
import { LanguageService } from '@services/internal/language.service';
import { GlobalStoreService } from '@services/internal/global-store.service';

/** Set title to store */
export const ScreenTitleDecorator =
  (pageTitle: TranslationsKeys) =>
  (constructor: any): void => {
    const originalAfterViewInit: any = constructor.prototype.ngAfterViewInit;
    constructor.prototype.ngAfterViewInit = function (): void {
      GlobalStoreService.screenTitle$.next(LanguageService.instant(pageTitle));
      return originalAfterViewInit && typeof originalAfterViewInit === 'function' && originalAfterViewInit.apply(this, arguments);
    };
  };
